import { ApprovalRequestStatus } from "./Enumerations";
import { GenericApiResponse } from "./payloads/generic-response";
import { AddBillerBeneficiaryRequestPayload } from "./payloads/beneficiaries";

export interface AirtimeBeneficiary {
    beneficiaryName: string;
    mobileNetwork: string;
    mobileNumber: string;
}

export interface AccountBeneficiary {
    beneficiaryName: string;
    accountNumber: string;
    accountTitle: string;
    bankName: string;
}

export interface AddBeneficiaryRequestPayload {
    sessionId: string;
    userID: string;
    beneficiaryAlias: string;
    beneficiaryName: string;
    beneficiaryAccountNumber: string;
    beneficiaryBank: string;
    beneficiaryBankCode: string;
    beneficiaryReference: string;
    customerReference: string;
    otp: string;
    otpReference: string;
    beneficiaryEmailAddress: string;
}

export interface EditBeneficiaryRequestPayload {
    sessionId: string;
    UserId: string;
    beneficiaryReference: string;
    OTP: string;
    BeneficiaryId: string;
    SourceReferenceId: string; // otpReference
    customerReference: string;
}

export interface RemoveBeneficiaryRequestPayload {
    sessionId: string;
    UserId: string;
    OTP: string;
    BeneficiaryId: string;
    SourceReferenceId: string; // otpReference
}

export interface Beneficiary {
    beneficiaryAlias: string;
    beneficiaryName: string;
    beneficiaryAccountNumber: string;
    beneficiaryBank: string;
    beneficiaryBankCode: string;
    beneficiaryReference: string;
    customerReference: string;
    beneficiaryId: string;
    groupId: string;
    color?: string;
    checked?: boolean;
    Id: number;
}

export interface BeneficiaryMgtApprovalRequest {
    beneficiaryName: string;
    beneficiaryId: string;
    requestedBy: string;
    requestDate: string;
    requestType: string;
    status: ApprovalRequestStatus;
}

export interface StagedBeneficiaryRequestObject {
    BatchId: number;
    Initiator: string;
    ApprovalStageId: number;
    ApproverId: number;
    NewBeneficiaryDetails: StagedBeneficiaryRequestObjectDetail;
}
export interface StagedBeneficiaryRequestObjectDetail extends AddBeneficiaryRequestPayload {
    status: string;
    Id: string;
    createdDate: string;
}

export interface StagedBeneficiaryRequestReponse extends GenericApiResponse {
    PaginatedItems: Array<StagedBeneficiaryRequestObject>;
    PageSize: number;
    ItemLastId: number;
}

export interface StagedBeneficiaryRequestApprovalPayload {
    BatchId: number;
    ApproverId: number;
    ApproverStageId: number;
    OTP: string;
    SourceReferenceId: string;
    UserId?: string;
    OperationType: string;
    NextStages?: Array<any>;
}


export class BillerBeneficiary {
    billerId: number;
    billerName: string;
    billerType: string;
    payeeFormat: string;
    billerCurrency: string;
    categoryId: number;
    categoryName: string;
    subscriptionIds: string;
    billerNickName: string;
    myReference: string;
    consumerCodedet: string;
    groupId: string;
}


export class Biller {
    billerId: number;
    billerName: string;
    billerType: string;
    payeeFormat: string;
    billerCurrency: string;
    categoryId: number;
    categoryName: string;
}




export class BillerCategory {
    categoryId: number;
    categoryName: string;
}


export class BillerProduct {
    billerId: number;
    productId: string;
    productCode: string;
    productName: string;
    paymentCurrency: string;
    isAmountFixed: string;
    paymentAmount: string;
}

export class ServiceOperator {
    operatorId: number;
    operatorName: string;
}

export class ServiceOperatorProduct {
    operatorId: number;
    productId: number;
    productCode: string;
    productName: string;
    isAmountFixed: string;
    amount: number;
}

export interface StagedBillerOperationRequestReponse extends GenericApiResponse {
    PaginatedItems: Array<StagedBillerOperationRequestObject>;
    PageSize: number;
    ItemLastId: number;
}

export interface StagedBillerOperationRequestObjectDetail extends AddBillerBeneficiaryRequestPayload {
    operationType: string;
    CreatedDate: string;
    Status: string;
    CheckSum: string;
    ApprovalRules: Array<any>;
    Id: number;
    intiator: string;
    billerBeneficiaryId: string;
}

export interface StagedBillerOperationRequestObject {
    BatchId: number;
    Initiator: string;
    ApprovalStageId: number;
    ApproverId: number;
    BeneficiaryDetails: StagedBillerOperationRequestObjectDetail;
}

export interface SSSBankingCommand {
    id: string;
    sourceAccountNo: string;
    sourceAccountName: string;
    destinationAccountNo: string;
    destinationAccountName: string;
    destinationBankCode: string;
    beneficiaryId: string;
    operationType: string;
    isRecurrent: string;
    transactionType: string; // "transfer",
    oneTimeLimit: string;
    dailyLimit: string;
    monthlyLimit: string;
    phoneNumber: string;
    nickName: string;
    otp: string;
    otpReference: string;
    userId: string;
    cifId: string;
}

