import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "./appconfig.service";
import { ApiURLConstants } from "../helpers/apiURLConstants";
import { Observable, of } from "rxjs";
import {
  LoadHardwareTokenStatusResponse,
  SubmitHardwareTokenSubscriptionRequest,
} from "../models/hardwaretoken";
import { GenericApiResponse } from "../models/payloads/generic-response";
import { BranchListResponse } from "../models/service-request/branch";

@Injectable()
export class HardwareTokenService {
  private _apiBaseUrl: string;
  constructor(
    private _httpClient: HttpClient,
    private _settingsService: AppConfigService
  ) {
//    this._apiBaseUrl = this._settingsService.HardwareTokenAPIBaseUrl;
    this._apiBaseUrl = this._settingsService.HardwareTokenAPIBaseUrl;
  }

  //LoadHardwareTokenStatusResponse
  getTokenSubscriptionStatus(
    cif: string
  ): Observable<LoadHardwareTokenStatusResponse> {
    // const res = ({
    //   Data: {
    //     TokenActivated: true,
    //     USSDActive: true,
    //     InternetBankingActive: true,
    //     MobileBanking: false,
    //   },
    //   ResponseCode: "00",
    //   ResponseDescription: "customers hardware token subscription active",
    // } as {}) as LoadHardwareTokenStatusResponse;

    // return of(res);

    return this._httpClient.post<LoadHardwareTokenStatusResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.GetHardwareTokenStatus}`,
      { cif_id: cif }
    );
  }

  createTokenSubscriptionStatus(
    payload: SubmitHardwareTokenSubscriptionRequest
  ): Observable<GenericApiResponse> {
    // const res = ({
    //   ResponseCode: "00",
    //   ResponseDescription: "customers hardware token subscription created",
    // } as {}) as LoadHardwareTokenStatusResponse;

    // return of(res);

    return this._httpClient.post<GenericApiResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.CreateHardwareTokenStatus}`,
      payload
    );
  }

  loadBranchList(userId: string): Observable<BranchListResponse> {
    return this._httpClient.post<BranchListResponse>(
      `${this._apiBaseUrl}/AccountManagement/GetBranchList`,
      { UserId: userId }
    );
  }
  
  loadPickupBranchList(): Promise<BranchListResponse> {
    return this._httpClient.get<BranchListResponse>(
      `${this._apiBaseUrl}/hardwaretokenmanager/get-branches`).toPromise();
  }

  updateTokenSubscriptionStatus(
    payload: SubmitHardwareTokenSubscriptionRequest
  ): Observable<GenericApiResponse> {
    // const res = ({
    //   ResponseCode: "00",
    //   ResponseDescription: "customers hardware token subscription created",
    // } as {}) as LoadHardwareTokenStatusResponse;

    // return of(res);

    return this._httpClient.post<GenericApiResponse>(
      `${this._apiBaseUrl}/${ApiURLConstants.UpdateHardwareTokenStatus}`,
      payload
    );
  }
}
