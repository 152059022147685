export interface AccountRequestPayload {
    CifId: string;
    userId: string;
    SessionId: string;
}

export interface SetDefaultAccountRequestPayload {
    UserId: string;
    AccountNo: string;
    SessionId: string;
}

export interface RequestResponse {
    ResponseCode: string;
    ResponseFriendlyMessage: string;
}

export interface AccountRequestResponse {
    ResponseCode: string;
    ResponseFriendlyMessage: string;
    defaultAccount: string; // "0021236296",
    Accounts: Array<CustomerAccount>;
}


export class CustomerAccount {
    accountNumber: string;
    accountName: string;
    debitAllowed: boolean;
    creditAllowed: boolean;
    accountType: string;
    accountCategory: string;
    effectiveBalance: number;
    availableBalance: number;
    currency: string;
    isNICard? : boolean;
    PanMasked? : string;
    Foracid?:string;
    ExpDate?:string;
}


export interface GetCustomerBVNRequest {
    SessionId: string;
    UserId: string;
    TokenId: string;
}




export class SMEUserCorporateLimitResponse {
    ResponseCode: string;
    ResponseDescription: string;
    ResponseFriendlyMessage: string;
    Token: string;
    CorporateLimitAvailable: number;
    UserLimitAvailable: number;
}


export class RetailUserEzCashLimitDetails {
    limit: number;
    minimumAmount: number;
    interestRate: number;
    bvn: string;
    MaximumLimit: number;
    RepaymentDay: string;
    lastAmountBorrowed: number;
    lastLoanTenure: number;
    totalOutstanding: number;
    MaxTenure: number;
}
