import { BaseApprovalComponent } from '../approvals/base.approval.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatDialog, MatDialogRef, PageEvent } from '@angular/material';

export class BasePaymentTransactionComponent extends BaseApprovalComponent {

    ownLoaderIsActive: boolean;
    ownloaderMsg: string;
    constructor(authService: AuthService, public _dialog: MatDialog) {
        super(authService);
    }

    showLoadingIndicator(loaderMsg) {
        this.ownLoaderIsActive = true;
        this.ownloaderMsg = loaderMsg;
    }

    hideLoadingIndicator() {
        this.ownLoaderIsActive = false;
        this.ownloaderMsg = '';
    }
}
